import {createWebHashHistory} from 'vue-router'

export default {
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      name: 'index',
      redirect: '/login',
      component: () => import('../views/index'),
      children: [
        {
          path: '/publish',
          name: 'publish',
          component: () => import('../views/publish/Index')
        },
        {
          path: '/history',
          name: 'history',
          component: () => import('../views/publish/History')
        },
        {
          path: '/editArtical',
          name: 'editArtical',
          component: () => import('../views/publish/Edit')
        },
        {
          path: '/category',
          name: 'category',
          component: () => import('../views/bask/Category')
        },
        {
          path: '/base',
          name: 'base',
          component: () => import('../views/bask/Base')
        },
        {
          path: '/password',
          name: 'password',
          component: () => import('../views/userInfo/PasswordSetting')
        },
      ]
    },
    {
      path: '/artical',
      name: 'artical',
      component: () => import('../views/publish/articalDetail')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../views/Login/login')
    },
    {
      path: '/newsDetail',
      name: 'newsDetail',
      component: () => import('../views/newsDetail/newsDetail'),
      meta:{
        title:"乡土文化详情"
      },
    },
    {
      path: '/test',
      name: 'test',
      component: () => import('../views/Test')
    },
    {
      path: '/:pathMatch(.*)*',
      name: '404',
      component: () => import('../views/NotFound')
    },
  ]
}
