<template>
  <div>
    <div ref="toolbar"></div>
    <div ref="editor"></div>
  </div>
</template>

<script>
import '@wangeditor/editor/dist/css/style.css'
import {createEditor, createToolbar} from '@wangeditor/editor'
import * as ResourceApi from "../../../api/api/Resources"
import {AXIOS_BASE_URL} from "@/api/config";

export default {
  name: "Edit5-self",
  data() {
    return {
      editor: null,
      toolbar: null
    }
  },
  props: {
    /**
     * 内容
     */
    html: {
      type: String,
      default: ""
    }
  },
  watch: {
    /**
     * 设置初始内容
     * @param val
     */
    html(val) {
      this.editor.select([])
      this.editor.deleteFragment()
      this.editor.dangerouslyInsertHtml(val)
    }
  },
  computed: {
    /**
     * 编辑器配置
     * @return {{onChange: onChange, placeholder: string}}
     */
    editConfig() {
      let that = this
      return {
        placeholder: "请输入内容",
        onChange: this.editChange,
        MENU_CONF: {
          uploadImage: {
            async customUpload(file, insertFn) {
              that.uploadImg(file, insertFn)
            }
          },
          uploadVideo: {
            async customUpload(file, insertFn) {
              that.uploadVideo(file, insertFn)
            }
          },
          lineHeight: {
            lineHeightList: ['1', '1.5', '2', '2.5']
          }
        }
      }
    },
    /**
     * 工具栏配置
     * @return {{}}
     */
    toolbarConfig() {
      return {
        excludeKeys: ["emotion", "insertLink", "code", "codeBlock", "blockquote", "todo", "insertVideo"]
      }
    }
  },
  methods: {
    /**
     * 初始化
     */
    init() {
      this.editor = createEditor({
        selector: this.$refs.editor,
        config: this.editConfig,
        mode: 'default'
      })
      this.toolbar = createToolbar({
        editor: this.editor,
        selector: this.$refs.toolbar,
        config: this.toolbarConfig,
        mode: 'default' // 或 'simple' 参考下文
      })
    },
    /**
     * 编辑器修改
     */
    editChange() {
      if (this.editor) {
        this.$emit("editOnChange", this.editor.getHtml())
      }
    },
    // --- axios ---
    /**
     * 上传图片
     * @param file 图片
     * @param insertFn 插入图片的回调函数
     */
    uploadImg(file, insertFn) {
      let pd = new FormData()
      pd.append("Files", file)
      pd.append("text", "{type:03}")
      ResourceApi.UploadResources(pd)
          .then(res => {
            if (res.data.code === 200) {
              insertFn(AXIOS_BASE_URL + res.data.data[0].path)
            } else {
              this.$message.error(res.data.message)
            }
          })
          .catch(err => {
            console.error(err)
            this.$message.error("上传失败")
          })
    },
    /**
     * 上传视频
     * @param resultFiles 视频文件
     * @param insertVideoFn 插入视频的回调函数
     */
    uploadVideo(resultFiles, insertVideoFn) {
      let pd = new FormData()
      pd.append("Files", resultFiles)
      pd.append("text", "{type:02}")
      this.$message.info("正在上传视频...")
      ResourceApi.UploadResources(pd)
          .then(res => {
            this.$message.success("上传成功")
            if (res.data.code === 200) {
              insertVideoFn(AXIOS_BASE_URL + res.data.data[0].path)
            } else {
              this.$message.error(res.data.message)
            }
          })
          .catch(err => {
            console.error(err)
            this.$message.error("上传失败")
          })
    }
  },
  mounted() {
    this.init()
    console.log(this.editor.getConfig())
  }
}
</script>

<style scoped lang="less">
::v-deep(.w-e-text-container) {
  max-height: 420px;
  overflow-y: scroll;
  overflow-x: hidden;
}

::v-deep(.w-e-hover-bar) {
  display: none;
}

</style>