import axios from 'axios'
import {AXIOS_BASE_URL} from "@/api/config";
import {store} from "../vuex/index";
import router from "../routes/index"

const service = axios.create({
  baseURL: AXIOS_BASE_URL,
  timeout: 50000, // 请求超时uploadBuildExcel
  responseType: 'json',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
  },
})
service.interceptors.request.use(config => {
  if (!config.headers.token) {
    if (store.state.token === '') {
      router.push({path: "/login"})
      return config
    }
    config.headers.token = store.state.token
  }
  return config
})

export default service