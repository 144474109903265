<template>
  <el-cascader
      v-model="form.area"
      :options="options"
      v-bind="$attrs"
      ref="cascader"
      :props="props"
      clearable
      @change="selectArea">
    <template #default="{node,data}">
      <span @click="selectNode(node)">{{ data.label }}</span>
    </template>
  </el-cascader>
</template>

<script>
export default {
  data() {
    return {
      form: {
        area: [],
        areaNames: []
      },
      areaData: require("@/assets/json/ssq.json"),
      props: {expandTrigger: 'hover', checkStrictly: true}
    }
  },
  computed: {
    /**
     * 三级联动选项的数据
     * @return {{}}
     */
    options() {
      return this.areaData.province
    },
  },
  methods: {
    selectArea(val) {
      this.form.area = val
    },
    selectNode(val) {
      this.form.area = val.pathValues
      this.form.areaNames = val.pathLabels
      this.$emit("selectChange", this.form)
    }
  }
}
</script>

<style scoped>

</style>