<template>
  <div style="border: 1px solid #ccc">
    <Toolbar
        :editor="editorRef"
        :defaultConfig="toolbarConfig"
        :mode="mode"
        style="border-bottom: 1px solid #ccc"/>
    <Editor
        :defaultConfig="editorConfig"
        :mode="mode"
        v-model="valueHtml"
        style="height: 400px;width: 100%; overflow-y: scroll"
        @onCreated="handleCreated"
        @onChange="handleChange"
        @onDestroyed="handleDestroyed"
        @onFocus="handleFocus"
        @onBlur="handleBlur"
        @customAlert="customAlert"
        @customPaste="customPaste"/>
  </div>
</template>

<script>
import '@wangeditor/editor/dist/css/style.css';
import {onBeforeUnmount, ref, shallowRef, watchEffect} from 'vue';
import * as ResourceApi from "../../../api/api/Resources"
import {Editor, Toolbar} from '@wangeditor/editor-for-vue';
import {AXIOS_BASE_URL} from "@/api/config";

export default {
  components: {Editor, Toolbar},
  props: {
    html: {
      type: String,
      default: ""
    }
  },
  setup(props, context) {
    // 编辑器实例，必须用 shallowRef，重要！
    const editorRef = shallowRef();

    // 内容 HTML
    const valueHtml = ref(props.html);

    // 组件销毁时，也及时销毁编辑器，重要！
    onBeforeUnmount(() => {
      const editor = editorRef.value;
      if (editor == null) return;

      editor.destroy();
    });

    watchEffect(() => {
      valueHtml.value = props.html
    })
    const toolbarConfig = {
      excludeKeys: ["emotion", "insertLink", "code", "codeBlock", "blockquote", "todo", "insertVideo"]
    };

    /**
     * 编辑器修改
     */
    const editChange = () => {
      if (this.editor) {
        this.$emit("editOnChange", this.editor.getHtml())
      }
    }
    const editorConfig = {
      placeholder: "请输入内容",
      onChange: editChange,
      MENU_CONF: {
        uploadImage: {
          async customUpload(file, insertFn) {
            uploadImg(file, insertFn)
          }
        },
        uploadVideo: {
          async customUpload(file, insertFn) {
            uploadVideo(file, insertFn)
          }
        },
        lineHeight: {
          lineHeightList: ['1', '1.5', '2', '2.5']
        }
      }
    };

    // --- axios ---
    /**
     * 上传图片
     * @param file
     * @param insertFn
     */
    const uploadImg = (file, insertFn) => {
      let pd = new FormData()
      pd.append("Files", file)
      pd.append("text", "{type:03}")
      ResourceApi.UploadResources(pd)
          .then(res => {
            if (res.data.code === 200) {
              insertFn(AXIOS_BASE_URL + res.data.data[0].path)
            } else {
              this.$message.error(res.data.message)
            }
          })
          .catch(err => {
            console.error(err)
            this.$message.error("上传失败")
          })
    }


    /**
     * 上传视频
     * @param resultFiles 视频文件
     * @param insertVideoFn 插入视频的回调函数
     */
    const uploadVideo = (resultFiles, insertVideoFn) => {
      let pd = new FormData()
      pd.append("Files", resultFiles)
      pd.append("text", "{type:02}")
      this.$message.info("正在上传视频...")
      ResourceApi.UploadResources(pd)
          .then(res => {
            this.$message.success("上传成功")
            if (res.data.code === 200) {
              insertVideoFn(AXIOS_BASE_URL + res.data.data[0].path)
            } else {
              this.$message.error(res.data.message)
            }
          })
          .catch(err => {
            console.error(err)
            this.$message.error("上传失败")
          })
    }

    // 编辑器回调函数
    const handleCreated = (editor) => {
      editorRef.value = editor; // 记录 editor 实例，重要！
    };
    const handleChange = (editor) => {
      context.emit("editOnChange", editor.getHtml())
    };
    const handleDestroyed = (editor) => {
      console.log('destroyed', editor);
    };
    const handleFocus = (editor) => {
      console.log('focus', editor);
    };
    const handleBlur = (editor) => {
      console.log('blur', editor);
    };
    const customAlert = (info, type) => {
      alert(`【自定义提示】${type} - ${info}`);
    };
    const customPaste = (editor, event, callback) => {
      console.log('ClipboardEvent 粘贴事件对象', event);

      // 自定义插入内容
      // editor.insertText('xxx');

      // 返回值（注意，vue 事件的返回值，不能用 return）
      // callback(false); // 返回 false ，阻止默认粘贴行为
      callback(true) // 返回 true ，继续默认的粘贴行为
    };
    // todo 编辑器中空格不能用
    const insertText = () => {
      const editor = editorRef.value;
      if (editor == null) return;
      editor.insertText('hello world');
    };

    const printHtml = () => {
      const editor = editorRef.value;
      if (editor == null) return;
      console.log(editor.getHtml());
    };

    const disable = () => {
      const editor = editorRef.value;
      if (editor == null) return;
      editor.disable()
    };

    return {
      editorRef,
      mode: 'default',
      valueHtml,
      toolbarConfig,
      editorConfig,
      handleCreated,
      handleChange,
      handleDestroyed,
      handleFocus,
      handleBlur,
      customAlert,
      customPaste,
      insertText,
      printHtml,
      disable
    };
  },
};
</script>
