import ResizeListener from 'element-resize-detector';
export default {
    methods: {
        data() {
            return {
                chart: null,
            }
        },
        /* 对chart元素尺寸进行监听，当发生变化时同步更新echart视图 */
        chartEleResizeListener() {
            const chartInstance = ResizeListener({
                strategy: 'scroll',
                callOnAdd: true
            });
            chartInstance.listenTo(this.$el, () => {
                if (!this.chart) return;
                this.chart.resize();
            });
        },
        /* 当窗口缩放时，echart动态调整自身大小 */
        windowResizeListener() {
            if (!this.chart) return;
            this.chart.resize();
        },
        // ---------- 销毁时释放资源 -------------
        chartDispost: function () {
            if (!this.chart) {
                return
            }
            this.chart.dispose()
            this.chart = null
        }
    },
    mounted() {
        window.addEventListener('resize', this.windowResizeListener);
        this.chartEleResizeListener();
        // ---------- 创建 echarts
        var echarts = require("echarts");
        // 基于准备好的dom，初始化echarts实例
        this.chart = echarts.init(this.$el);
        // 绘制图表
        this.chart.setOption(this.option);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.windowResizeListener);
        this.chartDispost();
    },
}