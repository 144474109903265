import {
  Expand,
  User,
  Fold,
  Menu,
  RefreshLeft,
  CaretTop,
  CaretBottom,
  Iphone,
  Lock,
  Checked
} from '@element-plus/icons-vue'

export default {
  components: {
    Expand,
    User,
    Fold,
    Menu,
    RefreshLeft,
    CaretTop,
    CaretBottom,
    Iphone,
    Lock,
    Checked
  }
}