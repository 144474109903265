import {createStore} from 'vuex'
import createPersistedState from "vuex-persistedstate";

export const store = createStore({
  state: {
    name: '',
    token: '',
    indexPath: ['用户管理', '/publish'],
    editInfo: {}
  },
  plugins: [createPersistedState()],
  mutations: {
    setName(state, data) {
      state.name = data
    },
    setToken(state, data) {
      state.token = data
    },
    setIndexPath(state, data) {
      state.indexPath = data
    },
    setEditInfo(state, data) {
      console.log("state",data)
      state.editInfo = data
    }
  },
})