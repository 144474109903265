<template>
  <div class="chartSize"></div>
</template>

<script>
import resize from "../reset";

export default {
  mixins: [resize],
  props: {
    currentData: {
      type: Array,
      default: function () {
        return [1,2,3];
      }
    },
    title: {
      type: String,
      default: "图标标题"
    },
    initData: {
      type: Array,
      default: () => {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    chartName: {
      type: String,
      default: "年统计"
    }
  },
  data() {
    return {
      chartData: [],
    };
  },
  computed: {
    option: function () {
      return {
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [150, 230, 224, 218, 135, 147, 260],
            type: 'line'
          }
        ]
      }
    },
  },
  methods: {
    /**
     * 更新数据
     */
    updateData: function () {
      let option = {
        series: [
          {
            name: this.legend,
            data: this.chartData,
            type: "line"
          },
        ],
      }
      this.chart.setOption(option);
    },
    /**
     * 获取本月每一天
     * */
    getCountDays() {
      let curDate = new Date();
      let curMonth = curDate.getMonth();
      curDate.setMonth(curMonth + 1);
      curDate.setDate(0);
      return curDate.getDate();
    },
    /**
     * 获取本月每一天
     * */
    getEveryDay() {
      let dayArry = [];
      let day = this.getCountDays();
      for (let k = 1; k <= day; k++) {
        dayArry.push(k);
      }

      return dayArry;
    }
  },
  watch: {

    /**
     * 更新当前值
     * @param val
     */
    currentData: function (val) {
      this.chartData = val;
      this.updateData();
    },

    /**
     * 初始化数据
     * @param val
     */
    initData: function (val) {
      this.chartData = val;
      this.updateData();
    },

    /**
     * 是否进入loading
     * @param val
     */
    loading: function (val) {
      if (val) {
        this.chart.showLoading();
      } else {
        this.chart.hideLoading();
      }
    }
  },
  mounted() {
    this.chartData = this.currentData;
    this.updateData();
  }
}
</script>

<style scoped>
.chartSize {
  height: 100%;
  width: 100%;
}
</style>