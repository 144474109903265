// import Editor from "@/components/utils/wangeditor/Editor";
import Edit5Self from "@/components/utils/wangeditor/Edit5-self";
import BasicEditor from "@/components/utils/wangeditor/BasicEditor";
import WangEditorSelf from "@/components/utils/wangeditor/WangEditorSelf";
export default {
  components: {
    // Editor,
    Edit5Self,
    BasicEditor,
    WangEditorSelf
  }
}