import request from '../axios'

/**
 * 获取基地
 */
export function GetBaseList(data) {
  return request({
    url: 'rest/Resources/GetBaseList',
    method: 'get',
    params: data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  })
}

/**
 * 上传资源
 */
export function UploadResources(data) {
  return request({
    url: 'rest/Resources/UploadResources',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

/**
 * 获取类别
 */
export function GetCategoryList(data) {
  return request({
    url: 'rest/Resources/GetCategoryList',
    method: 'get',
    params: data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  })
}

/**
 * 发布资源
 */
export function ReleaseResources(data) {
  return request({
    url: 'rest/Resources/ReleaseResources',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    }
  })
}

/**
 * 获取发布历史
 */
export function GetArticleList(data) {
  return request({
    url: 'rest/Resources/GetArticleList',
    method: 'get',
    params: data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  })
}



/**
 * 文章禁用或启用
 */
export function UpdateStatusForArticle(data) {
  return request({
    url: 'rest/Resources/UpdateStatusForArticle',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  })
}


/**
 * 根据发布id获取文章信息和资源
 */
export function GetArticleForId(data) {
  return request({
    url: 'rest/Resources/GetArticleForId',
    method: 'get',
    params: data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  })
}


/**
 * 删除文章
 */
export function DeleteArticle(data) {
  return request({
    url: 'rest/Resources/DeleteArticle',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  })
}

