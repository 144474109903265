<template>
  <div style="border: 1px solid #ccc">
    <Toolbar
        :editor="editorRef"
        :defaultConfig="toolbarConfig"
        :mode="mode"
        style="border-bottom: 1px solid #ccc"/>
    <Editor
        :defaultConfig="editorConfig"
        :mode="mode"
        v-model="valueHtml"
        style="height: 400px; overflow-y: scroll"
        @onCreated="handleCreated"
        @onChange="handleChange"
        @onDestroyed="handleDestroyed"
        @onFocus="handleFocus"
        @onBlur="handleBlur"
        @customAlert="customAlert"
        @customPaste="customPaste"/>
  </div>
</template>

<script>
import '@wangeditor/editor/dist/css/style.css';
import { ref, shallowRef, } from 'vue';
import * as ResourceApi from "../../../api/api/Resources"
import {Editor, Toolbar} from '@wangeditor/editor-for-vue';
import {AXIOS_BASE_URL} from "@/api/config";

export default {
  components: {Editor, Toolbar},
  data() {
    return {
      editorRef: null,
      valueHtml: "",
      mode: 'default',
    }
  },
  computed: {
    toolbarConfig() {
      return {
        excludeKeys: ["emotion", "insertLink", "code", "codeBlock", "blockquote", "todo", "insertVideo"]
      }
    },
    editorConfig() {
      return {
        placeholder: "请输入内容",
        onChange: this.editChange,
        MENU_CONF: {
          uploadImage: {
            async customUpload(file, insertFn) {
              this.uploadImg(file, insertFn)
            }
          },
          uploadVideo: {
            async customUpload(file, insertFn) {
              this.uploadVideo(file, insertFn)
            }
          },
          lineHeight: {
            lineHeightList: ['1', '1.5', '2', '2.5']
          }
        }
      }
    }
  },
  methods: {

    // 编辑器回调函数
    handleCreated(editor) {
      console.log('created', editor);
      // this.editorRef.value = editor; // 记录 editor 实例，重要！
    },
    handleChange(editor) {
      console.log('change:', editor.getHtml());
    },
    handleDestroyed(editor) {
      console.log('destroyed', editor);
    },
    handleFocus(editor) {
      console.log('focus', editor);
    },
    handleBlur(editor) {
      console.log('blur', editor);
    },
    customAlert(info, type) {
      alert(`【自定义提示】${type} - ${info}`);
    },
    customPaste(editor, event, callback) {
      console.log('ClipboardEvent 粘贴事件对象111', event);

      // 返回值（注意，vue 事件的返回值，不能用 return）
      // callback(false); // 返回 false ，阻止默认粘贴行为
      callback(true) // 返回 true ，继续默认的粘贴行为
    },
    // --- axios ---
    /**
     * 上传图片
     * @param file
     * @param insertFn
     */
    uploadImg(file, insertFn) {
      let pd = new FormData()
      pd.append("Files", file)
      pd.append("text", "{type:03}")
      ResourceApi.UploadResources(pd)
          .then(res => {
            if (res.data.code === 200) {
              insertFn(AXIOS_BASE_URL + res.data.data[0].path)
            } else {
              this.$message.error(res.data.message)
            }
          })
          .catch(err => {
            console.error(err)
            this.$message.error("上传失败")
          })
    },

    /**
     * 上传视频
     * @param resultFiles 视频文件
     * @param insertVideoFn 插入视频的回调函数
     */
    uploadVideo(resultFiles, insertVideoFn) {
      let pd = new FormData()
      pd.append("Files", resultFiles)
      pd.append("text", "{type:02}")
      this.$message.info("正在上传视频...")
      ResourceApi.UploadResources(pd)
          .then(res => {
            this.$message.success("上传成功")
            if (res.data.code === 200) {
              insertVideoFn(AXIOS_BASE_URL + res.data.data[0].path)
            } else {
              this.$message.error(res.data.message)
            }
          })
          .catch(err => {
            console.error(err)
            this.$message.error("上传失败")
          })
    },

    /**
     * 编辑器修改
     */
    editChange() {
      if (this.editor) {
        this.$emit("editOnChange", this.editor.getHtml())
      }
    }
  },
  beforeCreate() {
    this.editorRef = shallowRef()
    this.valueHtml = ref('<p>hello</p>')
  }
};
</script>
