<template>
  <div class="chart-size"></div>
</template>

<style scoped>
.chart-size {
  height: 100%;
  width: 100%;
}
</style>


<script>
/**
 * echarts 动态折线图
 * @props:
 * 1.currentData 当前数据 x为时间戳格式 y为数值
 * 当currentData改变时数值队列将踢出队首元素 队尾插入currentData
 * 2.title 图标标题
 * 3.legend 图例名称 鼠标划过展示
 * 4.color 图标颜色 包括阴影
 * 5.initData 初始化数据
 */
import chartResize from "../reset";

export default {
  mixins: [chartResize],
  props: {
    currentData: {
      type: Object,
      default: function() {
        return {
          y: "",
          x: ""
        };
      }
    },
    title: {
      type: String,
      default: "图标标题"
    },
    legend: {
      type: String,
      default: "line1"
    },
    color: {
      type: String,
      default: 'rgb(89,189,92)'
    },
    initData: {
      type: Array,
      default: () => {
        return [];
      }
    },
    areaColorInfo: {
      type: Object,
      default: () => {
        return {
          colorStopYTop: 0, // y轴正向颜色渐变
          colorStopYDown: 1 // y轴负向颜色渐变
        };
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    max: {
      type: Number,
      default: undefined,
    }
  },
  data() {
    return {
      chartData: [],
      lastTime: 0,
    };
  },
  computed: {
    option: function() {
      return {
        title: {
          text: this.title,
          textStyle: {
            fontSize: 15,
            color: "#fff",
          },
          top: 5,
          left: 10,
        },
        grid: {
          height: "60%",
          top: 35,
          left: 55
        },
        xAxis: {
          type: "time",
          splitLine: {
            show: false
          },
          splitNumber: 2,
          axisLine: {
            lineStyle: {
              color: "#fff",
            },
          },
          axisLabel: {
            color: "#ffffff",
            formatter: function(params) {
              let timeObj = new Date(params)
              let hour = timeObj.getHours() >= 10 ? `${timeObj.getHours()}` : `0${timeObj.getHours()}`
              let min = timeObj.getMinutes() >= 10 ? `${timeObj.getMinutes()}` : `0${timeObj.getMinutes()}`
              let sec = timeObj.getSeconds() >= 10 ? `${timeObj.getSeconds()}` : `0${timeObj.getSeconds()}`
              return `${hour}:${min}:${sec}`
            }
          }
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: "#fff",
              width: 1,
            },
            show: true
          },
          boundaryGap: [0, '100%'],
          show: true
        },
        series: [
          {
            name: this.legend,
            cursor: "pointer",
            symbol: "emptyCircle",
            symbolSize: 7,
            data: this.chartData,
            type: "line",
            smooth: true,
            showBackground: true,
            backgroundStyle: {
              color: "rgba(220, 220, 220, 0.8)",
            },
            legendHoverLink: true,
            tooltip: {
              formatter: res => {
                let timeObj = new Date(res.data[0]);
                let timeStr = `${timeObj.getHours() >= 10 ? timeObj.getHours() : '0' + String(timeObj.getHours())}:${timeObj.getMinutes() >= 10 ? timeObj.getMinutes() : '0' + String(timeObj.getMinutes())}:${timeObj.getSeconds()>= 10 ? timeObj.getSeconds() : '0' + String(timeObj.getSeconds())}`
                return `${this.legend}:${res.data[1]}<br/>时间:${timeStr}`
              },
            },
            label: {
              color: "#fff",
              show: false,
              position: "top",
              formatter: res => {
                return res.data[1];
              }
            },
            lineStyle: {
              color: this.color
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: this.areaColorInfo.colorStopYTop,
                x2: 0,
                y2: this.areaColorInfo.colorStopYDown,
                colorStops: [{
                  offset: 0, color: this.color // 0% 处的颜色
                }, {
                  offset: 1, color: 'rgba(31, 35, 38,0.1)' // 100% 处的颜色
                }],
              }
            },
            showSymbol: true,
          },
        ],
        tooltip: {
          trigger: "item",
        },
      };
    },
  },
  methods: {
    /**
     * 更新数据
     */
    updateData: function() {
      let option;
      if (this.max === undefined) {
        option = {
          series: [
            {
              name: this.legend,
              data: this.chartData,
              type: "line",
            },
          ],
        };
      } else {
        option = {
          series: [
            {
              name: this.legend,
              data: this.chartData,
              type: "line",
            },
          ],
          yAxis: {
            max: this.max
          }
        };
      }
      this.chart.setOption(option);
    },
  },
  watch: {
    /**
     * 更新当前值
     * @param val
     */
    currentData: function(val) {
      let currentY = val.y;
      let currentX = val.x;
      if (currentX !== this.lastTime) {
        if (this.chartData.length < 5) {
          this.chartData.push([currentX, currentY]);
        } else {
          this.chartData.shift();
          this.chartData.push([currentX, currentY]);
        }
        this.updateData();
      }
      this.lastTime = currentX;
    },
    /**
     * 初始化数据
     * @param val
     */
    initData: function(val) {
      this.chartData = val;
      if (val.length === 0) {
        this.lastTime = new Date().getTime();
      } else {
        this.lastTime = val[val.length - 1][0];
      }
      this.updateData();
    },
    /**
     * 是否进入loading
     * @param val
     */
    loading: function(val) {
      if (val) {
        this.chart.showLoading();
      } else {
        this.chart.hideLoading();
      }
    }
  },
};
</script>